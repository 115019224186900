import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"
import Gist from "react-gist"
import HtmlToReact from "html-to-react"
import Layout from "../components/layout"

import "../styles/main.scss";

var htmlToReactParser = new HtmlToReact.Parser();

var processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

var processingInstructions = [
  {
    replaceChildren: true,
    shouldProcessNode: function (node) {
      return node.attribs && node.attribs['data-gist'] !== undefined && node.attribs['data-gist'].length > 0;
    },
    processNode: function (node, children, index) {
      var attributeValue = node.attribs['data-gist'];
      var gistId = attributeValue.substring(0, attributeValue.indexOf(".json"));
      return <Gist id={gistId}/>;
    }
  },
  {
    shouldProcessNode: function (node) {
      return true;
    },
    processNode: processNodeDefinitions.processDefaultNode,
  },
];

var isValidNode = function () {
  return true;
};

class PostTemplate extends Component {
  render() {
    const post = this.props.data.wordpressPost

    return (
      <Layout>
        <div className="item-color" style={{ padding: "20px", margin: "10px" }}>
          <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
          <h5 dangerouslySetInnerHTML={{ __html: post.date }} />
          { htmlToReactParser.parseWithInstructions(post.content, isValidNode, processingInstructions) }
          <Link to={"/"}>
            <svg role="img" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" height="30px" transform="scale (-1, 1)" style={{ verticalAlign:"middle", fill: "rgb(0, 119, 192)"}}>
              <path d="M434.39,264.93c0.24-0.35,0.45-0.72,0.66-1.09c0.05-0.09,0.1-0.17,0.15-0.26c0.19-0.35,0.35-0.7,0.51-1.06    c0.05-0.12,0.12-0.24,0.17-0.36c0.14-0.34,0.26-0.69,0.38-1.03c0.05-0.15,0.11-0.3,0.16-0.45c0.11-0.35,0.19-0.7,0.27-1.06    c0.04-0.16,0.08-0.31,0.11-0.47c0.08-0.42,0.14-0.84,0.19-1.26c0.01-0.1,0.03-0.2,0.04-0.3c0.11-1.07,0.11-2.14,0-3.21    c-0.02-0.17-0.05-0.34-0.07-0.52c-0.05-0.34-0.09-0.69-0.16-1.02c-0.05-0.24-0.12-0.48-0.17-0.72c-0.07-0.27-0.12-0.53-0.2-0.8    c-0.08-0.25-0.17-0.5-0.26-0.74c-0.09-0.24-0.17-0.49-0.27-0.73c-0.1-0.25-0.22-0.49-0.34-0.73c-0.11-0.23-0.21-0.46-0.33-0.69    c-0.13-0.25-0.29-0.49-0.43-0.74c-0.12-0.2-0.23-0.4-0.37-0.6c-0.2-0.3-0.42-0.59-0.65-0.88c-0.1-0.12-0.18-0.25-0.28-0.37    c-0.35-0.43-0.72-0.84-1.12-1.23L317.63,129.88c-6.25-6.25-16.38-6.25-22.63,0c-6.25,6.25-6.25,16.38,0,22.63l87.49,87.49H90.88    c-8.84,0-16,7.16-16,16s7.16,16,16,16H382.5L295,359.5c-6.25,6.25-6.25,16.38,0,22.63c3.12,3.12,7.22,4.69,11.31,4.69    s8.19-1.56,11.31-4.69l114.81-114.81c0.7-0.7,1.32-1.46,1.86-2.25C434.33,265.02,434.36,264.97,434.39,264.93z"/>
            </svg>
            <span>Back home</span>
          </Link>
        </div>
      </Layout>
    )
  }
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      title
      date(formatString: "YYYY-MM-DD")
      content
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`